import { Staff } from '../../types/Staff';

export const staffTitle = 'Meet Our Support Staff';

export const staffData: Staff[] = [
  {
    id: 1,
    name: 'Pamela Casper',
    title: 'Legal Assistant',
    about: `Pamela Casper (“Pam”) is a Legal Assistant at Langino Law PLLC.
        Ms. Casper has worked in the legal field for over thirty years.
        Before joining Langino Law PLLC, Ms. Casper worked with Mr.
        Langino at a national law firm. Ms. Casper has also worked for the
        North Carolina Court of Appeals for over twenty years as an
        Executive Assistant. At the North Carolina Court of Appeals she
        coordinated cases with the law clerks, shepadized and cited
        checked court opinions, and organized cases that were scheduled
        for hearing. In her free time, Ms. Casper loves to do anything
        outdoors. She takes care of four horses, two dogs, and over twenty
        chickens.`,
    imageUrl: 'pam-casper.jpg',
  },
  {
    id: 2,
    name: 'Ntombizodwa Mukondiwa',
    title: 'Paralegal',
    about: `Ntombizodwa Mukondiwa is a Paralegal at Langino Law PLLC. Ms. Mukondiwa has a law degree and a Master of Laws from the University of Zimbabwe. She has a Master’s Degree from the Southern and Eastern African Regional Centre for Women’s Law. In 2018, she received her Master of Laws degree from the University of North Carolina at Chapel Hill. Prior to joining Langino Law as a paralegal, Ms. Mukondiwa worked as an attorney at Gama and Partners Legal Practitioners in Harare, Zimbabwe. She also worked as a Magistrate for the Judicial Services Commission of Zimbabwe. In North Carolina, Ms. Mukondiwa has assisted the North Carolina Innocence Inquiry Commission in reviewing client cases. Further, she has externed for the North Carolina Commissioner of Banks, and has worked as a Project Manager and Litigation Document Reviewer for various companies. In her free time Ms. Mukondiwa enjoys reading and creative writing.`,
    imageUrl: 'ntombizodwa-mukondiwa.jpg',
  },
  {
    id: 3,
    name: 'Erika Ruiz',
    title: 'Paralegal',
    about: `Erika Ruiz is a Paralegal at Langino Law PLLC. Ms. Ruiz has a
        Bachelor of Education from the U.S. Institute of Language and
        Clerical Studies. She is also a N.C. State Bar Certified Paralegal
        having received her certificate from the University of North
        Carolina at Chapel Hill. Prior to joining Langino Law as a
        contract paralegal, Ms. Ruiz had five years of experience working
        as a Legal Receptionist and Paralegal at different local law
        firms. She has also worked as a medical interpreter for
        non-English speaking patients. She spends her free time
        volunteering as one of Jehovah's Witnesses in construction and
        disaster relief efforts for communities in need. Ms. Ruiz also
        volunteers as a Full-Time Minister with a focus on helping her
        community members receive a free Bible Education in their native
        language. She likes meeting new people and learning of different
        cultures. She speaks fluent Spanish and conversational French.`,
    imageUrl: 'erika-ruiz.jpeg',
  },
];
